// react-hot-loader must be imported before react & react-dom.
import { hot } from "react-hot-loader";

import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Layout, Card, Row, Col } from "antd";
import I18n from "i18n-js";
import ReactGA from "react-ga";
import CopyToClipboard from "react-copy-to-clipboard";

import { t } from "./lang";
import Editor from "./lang/Editor";
import appConfig from "./config/app";
import initStore from "./store/init";
import StepForm from "./components/StepForm";
import Confirm from "./components/Confirm";
import luxtagLogo from "./assets/img/luxtag-logo.png";
import pitchinLogo from "./assets/img/pitchin-logo.png";

const { store } = initStore();

const CardTitle = () => (
  <div style={{ textAlign: "center" }}>
    <div>
      <img src={luxtagLogo} style={styles.headerImage} />
      <img src={pitchinLogo} style={{ ...styles.headerImage, height: 60 }} />
    </div>
    <h2>{t("appName")}</h2>
  </div>
);

const App = () => {
  const [translations, setTranslations] = useState(
    I18n.translations[I18n.locale]
  );

  let showEditor = false;
  const urlParams = new URL(window.location.href).searchParams;
  if (urlParams.get("editor") === "true") {
    showEditor = true;
  }

  useEffect(() => {
    ReactGA.initialize(appConfig.googleAnalytic, {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const onEditorChange = (key, value, parent, data) => {
    I18n.translations[I18n.locale] = data;
    setTranslations(data);
  };

  return (
    <Provider store={store}>
      <Router>
        <Layout style={{ height: "100%", padding: "20px 10px 80px 10px" }}>
          <Layout.Content>
            <Row>
              {showEditor && (
                <Col span={12}>
                  <Editor
                    translations={translations}
                    onChange={onEditorChange}
                  />
                  <CopyToClipboard
                    text={JSON.stringify(translations)}
                    onCopy={() => alert("Translations copied to clipboard!")}
                    style={{ marginTop: 10 }}
                  >
                    <button>Copy translations to clipboard</button>
                  </CopyToClipboard>
                </Col>
              )}
              <Col span={showEditor ? 12 : 24}>
                <Card
                  title={<CardTitle />}
                  bordered={true}
                  style={{
                    width: "100%",
                    maxWidth: 600,
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                >
                  <Switch>
                    <Route path="/callback">
                      <Confirm />
                    </Route>
                    <Route path="/">
                      <StepForm />
                    </Route>
                  </Switch>
                </Card>
              </Col>
            </Row>
          </Layout.Content>
        </Layout>
      </Router>
    </Provider>
  );
};

const styles = {
  headerImage: {
    display: "inline-block",
    margin: 10,
    height: 50
  }
};

export default hot(module)(App);
