import React, { useState } from "react";
import { Result, Button, Spin } from 'antd';
import qs from "query-string";

const USE_LOCALHOST = false;
const verifyEndpoint = USE_LOCALHOST ? 'http://localhost:3000' : 'https://luxtag.equity.pitchin.my'

const requiredParams = [
  "orderId",
  "success"
];

const updateStatus = async (params) => {
  const query = qs.stringify(params)
  const response = await fetch(verifyEndpoint + '/api/confirm?' + query)
  let data = await response.json()
  return data
}

export default () => {

  const [loading, setLoading] = useState(true)

  const params = qs.parse(window.location.search);
  for (const paramName of requiredParams) {
    if (!params[paramName]) {
      return <div>error: missing param: {paramName}</div>;
    }
  }

  updateStatus(params)
    .then(reply => setLoading(false))

  return (
    <div>
      {loading ?
        <div style={{textAlign: 'center'}}>
          <Spin/>
          <p>Updating your payment status</p>
        </div>
        :
        <Result
          status="success"
          title="Thank you for your contribution to Luxtag"
          subTitle="Your payment status has been confirmed"
          extra={
            <Button
              type='primary'
              size="large"
              onClick={() => window.open('https://invest.luxtag.io/#invest', '_self')}
            >
              Check Fundraising Progress
            </Button>
          }
        />
      }
    </div>
  )
};
