// Real price
export const pricePerShareMYR = 160;
export const minSharesQty = 10;
export const pricePerBlockMYR = pricePerShareMYR * minSharesQty;
export const minQtyBlock = 4;


// Test price
// export const pricePerShareMYR = 10;
// export const minSharesQty = 1;
// export const pricePerBlockMYR = pricePerShareMYR * minSharesQty;
// export const minQtyBlock = 1;