import React from "react";

// const storeId = "AvUggPiTJJUNL8qFajgTNPXUnhWk9sM59YQMo5rsTo16";
const storeId = "6oGEGMdRRHNdYY4kdTYnuXKQCAttfiRLNa7gzTXDBLZb";

export default ({ onclick, price, currency, orderId }) => {
  const redirectUrl = 'https://luxtag.equity.pitchin.my/callback?success=1&orderId='+orderId
  return (
    <form
      target="_blank"
      method="POST"
      action="https://btcpayjungle.com/api/v1/invoices"
      className="btcpay-form btcpay-form--block"
    >
      <p style={{fontWeight: 700}}>
        Pay now with BTCPay
      </p>
      <input type="hidden" name="storeId" value={storeId} />
      <input type="hidden" name="price" value={price} />
      <input type="hidden" name="browserRedirect" value={redirectUrl} />
      <input type="hidden" name="currency" value={currency} />
      <input
        onClick={onclick}
        type="image"
        className="submit"
        name="submit"
        src="https://btcpayjungle.com/img/paybutton/pay.svg"
        alt="Pay with BTCPay, Self-Hosted Bitcoin Payment Processor"
      />
    </form>
  );
}
