import React, { useEffect } from "react";
import { JSONEditor } from "react-json-editor-viewer";

// adjust json editor style.
const adjustEditor = () => {
  // resize input elements according to content width
  const inputElements = document.querySelectorAll("div.editor-container input");

  inputElements.forEach(el => {
    el.addEventListener("input", resizeInput);
    resizeInput.call(el);
  });

  function resizeInput() {
    const padding = 3;
    const length = this.value.length + padding;
    this.style.width = length + "ch";
  }

  // disable add & delete field
  document
    .querySelectorAll('span[title="add item"], span[title="remove item"]')
    .forEach(el => {
      el.remove();
    });
};

const Editor = ({ translations, onChange }) => {
  useEffect(() => {
    adjustEditor();
    document.addEventListener("click", function(e) {
      if (["▼", "►"].includes(e.target.innerText)) {
        adjustEditor();
      }
    });
  });

  return (
    <div className="editor-container">
      <JSONEditor
        view="single"
        collapsible
        data={translations}
        onChange={onChange}
      />
    </div>
  );
};

export default Editor;
