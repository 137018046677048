export default {
  appName: "Luxtag Investment Form",
  formName: {
    information: "Information",
    verification: "Verification",
    payment: "Payment",
    checkout: "Checkout"
  },
  profileForm: {
    fields: {
      name: "Name",
      email: "Email",
      phoneNumber: "Phone number",
      nationality: "Nationality",
      address: "Address"
    },
    hints: {
      address: "Please include your country of residence in the last address line."
    }
  },
  paymentForm: {
    fields: {
      item: "Item",
      qty: "Quantity",
      paymentMethod: "Payment method",
      total: "Total"
    },
    hints: {
      qty: 'Minimum investment is %{minQtyBlock} blocks.',
      paymentMethodBitcoin: 'TODO: Some intro note on how pay with Bitcoin works',
      paymentMethodXem: 'TODO: Some intro note on how pay with XEM works',
    }
  }
};
