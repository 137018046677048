import React from "react";
import { connect } from "react-redux";
import { setUser } from "#src/store/actions/userActions";

import { Form, Icon, Radio, Upload, Button } from "antd";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class VerificationForm extends React.Component {

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const isRetailInvestor = this.props.user.investorType.value === 3;

    return (
      <Form
        {...formItemLayout}
        onSubmit={this.handleSubmit}
        style={{ textAlign: "left" }}
      >
        <p>
          <span style={styles.requiredStar}>*</span>
          1. Please self-declare which investor type are you. Only "Angel" and
          "Sophisticated" investors can invest using Bitcoin/NEM payment method.
          (We don’t require additional proofs of your declaration)
        </p>
        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
          {getFieldDecorator("investorType", {
            rules: [{ required: true, message: "Please select investor type" }]
          })(
            <Radio.Group>
              <div>
                <Radio style={radioStyle} value={1}>
                  Angel Investor
                </Radio>
                <p style={styles.radioHelpText}>
                  Your gross annual income is not less than MYR 180,000 (approx.
                  US$43,000) in the preceding period of 12 months; or jointly
                  with your spouse, the gross annual income of MYR 250,000
                  (approx. US$60,000)
                </p>
              </div>
              <div>
                <Radio style={radioStyle} value={2}>
                  Sophisticated Investor
                </Radio>
                <p style={styles.radioHelpText}>
                  Your net personal assets exceed MYR 3 million (approximately
                  US$718,000)
                </p>
              </div>
              <div>
                <Radio style={radioStyle} value={3}>
                  Retail Investor
                </Radio>
                {isRetailInvestor && (
                  <p style={styles.radioHelpText}>
                    Kindly proceed to{" "}
                    <a target="_blank" href="https://equity.pitchin.my/businesses/luxtag-sdnbhd">
                      https://equity.pitchin.my/businesses/luxtag-sdnbhd
                    </a>{" "}
                    and invest a max. amount of MYR 5,000 (approx. US$1,200) via
                    bank transfer
                  </p>
                )}
              </div>
            </Radio.Group>
          )}
        </Form.Item>
        {!isRetailInvestor && (
          <div>
            <p>
              <span style={styles.requiredStar}>*</span>
              2. Kindly upload a picture of your Government-issued ID (e.g.
              Passport, License).
            </p>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              {getFieldDecorator("upload", {
                valuePropName: "fileList",
                getValueFromEvent: this.normFile
              })(
                <Upload name="file" customRequest={dummyRequest} action="" listType="picture">
                  <Button>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </div>
        )}
      </Form>
    );
  }
}

const styles = {
  requiredStar: {
    display: 'inline-block',
    marginRight: '4px',
    color: '#f5222d',
    fontSize: '14px',
    fontFamily: 'SimSun, sans-serif',
    lineHeight: '1',
    content: '*'
  },
  radioHelpText: {
    marginLeft: 25,
    color: "grey",
    lineHeight: "1.5em",
    fontSize: "0.9em"
  }
};


const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({
  name: "verification",
  onFieldsChange(props, changedFields) {
    props.setUser(changedFields);
  },
  mapPropsToFields(props) {
    return {
      investorType: Form.createFormField(props.user.investorType),
      upload: Form.createFormField(props.user.upload)
    };
  },
 })(VerificationForm));
