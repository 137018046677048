import React from "react";
import { connect } from "react-redux";
import { setUser } from "#src/store/actions/userActions";
import { Form, Input, Select } from "antd";

import nationalities from "../../assets/nationalities";
import { t } from "#src/lang";

const { TextArea } = Input;

const { Option } = Select;

class InformationForm extends React.Component {

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form
        {...formItemLayout}
        style={{ textAlign: "left" }}
      >
        <Form.Item label={t("profileForm.fields.name")}>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please input your name"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t("profileForm.fields.email")}>
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "The input is not valid E-mail"
              },
              {
                required: true,
                message: "Please input your E-mail"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t("profileForm.fields.phoneNumber")}>
          {getFieldDecorator("phone")(<Input style={{ width: "100%" }} />)}
        </Form.Item>
        <Form.Item label={t("profileForm.fields.nationality")}>
          {getFieldDecorator("nationality", {
            rules: [
              { required: true, message: "Please select your nationality" }
            ]
          })(
            <Select
              showSearch
            >
              {nationalities.map((nationality, i) => (
                <Option key={`nationality-${i}`} value={nationality}>
                  {nationality}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={t("profileForm.fields.address")}
          help={t("profileForm.hints.address")}
        >
          {getFieldDecorator("address", {
            rules: [
              {
                required: true,
                message: "Please fill in your address"
              }
            ]
          })(<TextArea rows={4} />)}
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({
  name: "information",
  onFieldsChange(props, changedFields) {
    props.setUser(changedFields);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.user.name,
        value: props.user.name.value,
      }),
      email: Form.createFormField(props.user.email),
      phone: Form.createFormField(props.user.phone),
      nationality: Form.createFormField(props.user.nationality),
      address: Form.createFormField(props.user.address),
    };
  },
 })(InformationForm));
