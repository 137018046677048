import { setUser } from '#src/store/actions/userActions';
import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import ReactGA from "react-ga";

import BTCPay from '../BTCPay';
import XEMPay from '../XEMPay';


class CheckoutForm extends React.Component {

  showConfirm = () => {
    ReactGA.event({
      category: 'Payment',
      action: 'Initiate payment processing',
      label: this.props.user.paymentMethod.value
    });
    Modal.info({
      centered: true,
      title: 'Please continue in the payment window',
      content: (
        <div>
          <p>You may now close this page</p>
        </div>
      ),
      onOk() {},
    });
  }

  render() {
    return (
      <div>
        {this.props.user.paymentMethod.value === "BTCPAY" && (
          <div style={styles.paymentMethodContent}>
            <BTCPay
              onclick={this.showConfirm}
              orderId={this.props.orderId}
              currency="MYR"
              price={this.props.user.totalPayable}
            />
          </div>
        )}
        {this.props.user.paymentMethod.value === "XEM" && (
          <div style={styles.paymentMethodContent}>
            <XEMPay
              onclick={this.showConfirm}
              orderId={this.props.orderId}
              currency="MYR"
              price={this.props.user.totalPayable}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  paymentMethodContent: {
    marginLeft: 25
  }
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutForm);
