import { actionTypes } from "../actions/userActions";
import { pricePerBlockMYR, minQtyBlock } from '../../config/priceList'

const initialState = {
  address: '',
  email: '',
  investorType: '',
  name: '',
  nationality: '',
  phone: '',
  paymentMethod: {
    value: ''
  },
  qty: {
    value: minQtyBlock
  },
  totalPayable: minQtyBlock * pricePerBlockMYR,
};

const userReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER:
      // console.log('SET_USER', payload)
      return {
        ...state,
        ...payload
      };
    default:
      return initialState;
  }
};

export default userReducer;
