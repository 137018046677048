// Action types //

export const actionTypes = {
  SET_USER: "SET_USER"
};

// Action creators //

export const setUser = (user: object) => {
  return { type: actionTypes.SET_USER, payload: user };
};
