import { t } from '#src/lang';
import { Button, message, Spin, Steps } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import ReactGA from "react-ga";

import CheckoutForm from './steps/Checkout';
import InformationForm from './steps/Information';
import PaymentForm from './steps/Payment';
import VerificationForm from './steps/Verification';

const { Step } = Steps;

class StepForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      orderId : '',
      current: 0,
      bailOut: false
    };
  }

  validateForm(current) {
    const validateData = [
      ['name', 'email', 'nationality', 'address'],
      ['investorType', 'upload'],
      ['paymentMethod']
    ]
    let isValid = false
    try {
      isValid = validateData[current].every(k => {
        return this.props.user[k].value.toString().length > 0 && this.props.user[k].touched
      })
    } catch (e) {
      return {
        isValid: false,
        err: 'Please fill in all required fields'
      }
    }
    return {
      isValid,
      err: isValid ? '' : 'Please fill in all required fields'
    }

  }

  async saveForm() {
    const {isValid, err} = this.validateForm(this.state.current)
    if (!isValid) {
      message.error(err)
      return;
    }

    let startTime, endTime;

    if (window.performance) {
      startTime = performance.now()
    }
    this.setState({loading: true})
    const formData = new FormData();

    const data = {
      name: this.props.user.name.value,
      email: this.props.user.email.value,
      phone: this.props.user.phone.value,
      nationality: this.props.user.nationality.value,
      address: this.props.user.address.value,
      investorType: this.props.user.investorType.value,
      paymentMethod: this.props.user.paymentMethod.value,
      qty: this.props.user.qty.value,
      totalPayable: this.props.user.totalPayable,
    }

    formData.append('data', JSON.stringify(data))

    await Promise.all(
      this.props.user.upload.value.map((file, idx)=> {
        return new Promise((resolve, reject) => {
          formData.append(`files[${idx}]`, file.originFileObj)
          resolve(true)
        })

      })
    )
    ReactGA.event({
      category: 'Form',
      action: 'Save',
      label: data.paymentMethod
    });

    const response = await fetch('https://luxtag.equity.pitchin.my/api/checkout', {
      method: 'POST',
      body: formData
    })
    this.setState({loading: false})
    const result = await response.json();

    // Track how long submission of form took

    if (window.performance) {
      endTime = performance.now()
      try {
        const elapsedTime = endTime - startTime;
        ReactGA.timing({
          category: 'Form',
          variable: 'Save',
          value: elapsedTime, // in milliseconds
          label: 'Save to Google Form'
        });
      } catch (e) {
        console.error(e)
        // TODO: Send event to sentry
      }
    }

    if(result.submissionId) {
      this.setState({orderId: result.submissionId})
      this.next()
    }
  }

  next() {
    const {isValid, err} = this.validateForm(this.state.current)
    if (!isValid) {
      message.error(err)
      return;
    }
    const current = this.state.current + 1;
    const steps = [
      t("formName.information"),
      t("formName.verification"),
      t("formName.payment"),
      t("formName.checkout")
    ];
    ReactGA.event({
      category: 'Form',
      action: 'Next',
      label: steps[current]
    });
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    const steps = [
      t("formName.information"),
      t("formName.verification"),
      t("formName.payment"),
      t("formName.checkout")
    ];
    ReactGA.event({
      category: 'Form',
      action: 'Previous',
      label: steps[current]
    });
    this.setState({ current });
  }

  setBailOut = () => {
    this.setState({ bailOut: true });
  };

  render() {
    const { current, loading, orderId } = this.state;

    const steps = [
      {
        title: t("formName.information"),
        content: <InformationForm />
      },
      {
        title: t("formName.verification"),
        content: <VerificationForm />
      },
      {
        title: t("formName.payment"),
        content: <PaymentForm />
      },
      {
        title: t("formName.checkout"),
        content: <CheckoutForm orderId={orderId} />
      }
    ];

    return (
      <Spin
        spinning={loading}
        delay={500}
        tip='Saving your details'
      >
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div style={{ ...styles.stepsContent }}>
          {steps[current].content}
        </div>
        <div style={{ ...styles.stepsAction }}>
          {current === 0 &&
          (
            <Button
              type="primary"
              onClick={() => this.next()}
              style={{ float: "right" }}
            >
              Next
            </Button>
          )}
          {current === steps.length - 2 && (
            <Button
              loading={loading}
              type="primary"
              onClick={() => this.saveForm()}
              style={{ float: "right" }}
            >
              Checkout
            </Button>
          )}
          {current === 1 &&
          this.props.user.investorType.value !== 3 &&
          (
            <Button
              type="primary"
              onClick={() => this.next()}
              style={{ float: "right" }}
            >
              Next
            </Button>
          )}
          {current === 1 &&
           this.props.user.investorType.value === 3 &&
            (
              <Button
                type="primary"
                style={{ float: "right" }}
              >
                Done
              </Button>
            )
          }
          {current > 0 && (
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => this.prev()}
              style={{ float: "left" }}
            >
              Previous
            </Button>
          )}
        </div>
      </Spin>
    );
  }
}

const styles = {
  stepsContent: {
    minHeight: 500,
    marginTop: 16,
    border: "1px dashed #e9e9e9",
    borderRadius: 6,
    backgroundColor: "#fafafa",
    textAlign: "center",
    padding: 20
  },
  stepsAction: {
    marginTop: 24
  }
};

const mapStateToProps = state => ({ user: state.user });
export default connect(
  mapStateToProps
)(StepForm);
