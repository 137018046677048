// Adapted from https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975

import { createStore, combineReducers } from "redux";
import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  user: userReducer
});

// Run `initStore()` in root component to initialize redux
const initStore = () => {
  const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return { store };
};

export default initStore;
