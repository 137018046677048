import React from "react";
import { Form, InputNumber, Input, Radio } from "antd";

import { connect } from "react-redux";
import { setUser } from "#src/store/actions/userActions";
import { t } from "#src/lang";
import { pricePerBlockMYR, minQtyBlock } from '#src/config/priceList'

const defaultQty = minQtyBlock;
const currency = "MYR";

const formatMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency
}).format;

class PaymentForm extends React.Component {
  state = {
    total: defaultQty * pricePerBlockMYR,
    paymentMethod: ""
  };

  onChangeQty = val => {
    this.setState({
      total: val * pricePerBlockMYR
    });
    this.props.setUser({totalPayable: val * pricePerBlockMYR})
  };

  onChangePaymentMethod = e => {
    this.setState({
      paymentMethod: e.target.value
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form
        {...formItemLayout}
        onSubmit={this.handleSubmit}
        style={{ textAlign: "left" }}
      >
        <Form.Item label={t("paymentForm.fields.item")}>
          {getFieldDecorator("item", {
            rules: [
              {
                required: true,
                message: "Please input your name"
              }
            ],
            initialValue: `Luxtag Equity · ${currency} ${pricePerBlockMYR} per block`,
            disabled: true
          })(<Input disabled />)}
        </Form.Item>
        <Form.Item
          label={t("paymentForm.fields.qty")}
          help={t("paymentForm.hints.qty", { minQtyBlock })}
        >
          {getFieldDecorator("qty", {
            rules: [
              {
                type: "number",
                message: "The input is not valid number"
              },
              {
                required: true,
                message: "Please input quantity"
              }
            ],
            initialValue: defaultQty
          })(
            <InputNumber
              style={{ width: "100%" }}
              min={minQtyBlock}
              onChange={this.onChangeQty}
            />
          )}
        </Form.Item>
        <Form.Item label={t("paymentForm.fields.total")}>
          <InputNumber
            style={{ width: "100%" }}
            value={`${formatMoney(this.state.total)}`}
            disabled
          />
        </Form.Item>
        <Form.Item label={t("paymentForm.fields.paymentMethod")}>
          {getFieldDecorator("paymentMethod", {
            rules: [{ required: true, message: "Please select payment method" }]
          })(
            <Radio.Group
              onChange={this.onChangePaymentMethod}
              initialValue={this.state.paymentMethod}
              style={{ marginTop: 5 }}
            >
              <div>
                <Radio style={radioStyle} value={"BTCPAY"}>
                  Bitcoin
                </Radio>
              </div>
              <div>
                <Radio style={radioStyle} value={"XEM"}>
                  XEM
                </Radio>
              </div>
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({
  name: "payment",
  onFieldsChange(props, changedFields) {
    props.setUser(changedFields);
  },
  mapPropsToFields(props) {
    return {
      qty: Form.createFormField(props.user.qty),
      paymentMethod: Form.createFormField(props.user.paymentMethod)
    };
  },
 })(PaymentForm));
