import React, { useState } from "react";
import { Button, Spin, Tag } from 'antd'

export default ({ onclick, price, currency, orderId }) => {
  const recipientAddress = "NDT2MMF43MPB2XFZSFY72UPKQX6A53RQLXOKR5XP";
  const networkType = 'mainnet'
  const redirectUrl = 'https://luxtag.equity.pitchin.my/callback?success=1&orderId='+orderId
  const checkoutDesc = 'LuxTag Pitchin Crowdfunding'

  // console.log(exchange)
  const [amount, setAmount] = useState(-1)
  const [fetching, setFetching] = useState(true)
  const [rate, setRate] = useState(0)

  fetch('https://luxtag.equity.pitchin.my/api/xemRate?currency='+currency)
  // fetch('http://localhost:3000/api/xemRate?currency='+currency)
    .then(resp => resp.json())
    .then(exchange => {
      // console.log(exchange)
      if(exchange[0].price_myr){
        setAmount((price / exchange[0].price_myr).toFixed(6))
        setRate(exchange[0].price_myr)
      } else {
        setFetching(false)
      }
    })

  return (
    <div>
      {
        amount < 0 ?
          fetching ?
            <div>
              <Spin /> Fetching current MYR/XEM exchange rates...
            </div> :
            <div>
              Error fetching rates. Please contact support@luxtag.io
            </div> :
          <form
            target="_blank"
            method="GET"
            action="https://xempay.luxtag.io"
          >
            {/* <Tag style={styles.customTag} color="orange">
              <b>Note</b>: Please double check the recipient address in the payment gateway
            </Tag> */}
            <p>
              <span style={{fontWeight: 700}}>
                Current exchange rate:
              </span> {rate} MYR/XEM
            </p>
            <p>
              <span style={{fontWeight: 700}}>
                Total in XEMs:
              </span> {amount} XEMs
            </p>
            {/* <p>
              <span style={{fontWeight: 700}}>
                Payment Address:
              </span> {recipientAddress}
            </p> */}
            <input type="hidden" name="orderId" value={orderId} />
            <input type="hidden" name="recipientAddress" value={recipientAddress} />
            <input type="hidden" name="amount" value={amount} />
            <input type="hidden" name="redirectUrl" value={redirectUrl} />
            <input type="hidden" name="checkoutDesc" value={checkoutDesc} />
            <input type="hidden" name="networkType" value={networkType} />
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              onClick={onclick}
            >
              Pay now with XEMPay
            </Button>
          </form>
      }

    </div>
  )
}

const styles = {
  customTag: {
    whiteSpace: 'normal',
    padding: '8px 16px',
    fontSize: 14,
    // margin: 0,
    marginBottom: '10px',
    width: '100%',
  }

}